body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  color-scheme: dark;
}

button {
  cursor: pointer;
}

.ptr__children {
  display: flex;
}

.scrolling-gradient {
  --bg-size: 500%;
  --color-one: hsl(0 0% 25%);
  --color-two: hsl(0 0% 75%);
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent !important;
  background-clip: text;
  -webkit-background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .scrolling-gradient {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}
